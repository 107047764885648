const dev = {
  FHIR_TERM_SERVICE_SERVER_URL: "http://localhost:8080",
};

const prod = {
  FHIR_TERM_SERVICE_SERVER_URL:
    "https://termservice.dev.zengio.se",
};

const config = process.env.NODE_ENV === "development" ? dev : prod;

export default config;
