import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const CodeSystem: React.FC = (props) => {
  const { id } = useParams();

  useEffect(() => {
    //get more data on the code system
  }, [id]);

  return <>Code system with id: {id}</>;
};

export default CodeSystem;
