import { useEffect, useState } from "react";
import { CodeSystemModel } from "../models/CodeSystemModel";
import { getCodeSystems } from "../services/data";
import { ValueSetModel } from "../models/ValueSetModel";
import { getValueSets } from "../services/data";
import { ConceptMapModel } from "../models/ConceptMapModel";
import { getConceptMaps } from "../services/data";
import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  link: {
    display: "block",
    color: "black",
    textDecoration: "none",
  },
});

const Home: React.FC = () => {
  const classes = useStyles();

  const [codeSystems, setCodeSystems] = useState<CodeSystemModel[]>([]);
  const [valueSets, setValueSets] = useState<ValueSetModel[]>([]);
  const [conceptMaps, setConceptMaps] = useState<ConceptMapModel[]>([]);

  useEffect(() => {
    let isCancelled = false;

    getCodeSystems()
      .then((data) => {
        if (!isCancelled) {
          setCodeSystems(data);
        }
      })
      .catch((error) => console.log(error));

    getValueSets()
      .then((data) => {
        if (!isCancelled) {
          setValueSets(data);
        }
      })
      .catch((error) => console.log(error));

    getConceptMaps()
      .then((data) => {
        if (!isCancelled) {
          setConceptMaps(data);
        }
      })
      .catch((error) => console.log(error));

    return () => {
      isCancelled = true;
    };
  }, []);

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        height: "calc(100vh - 64px)",
        overflow: "auto",
      }}
    >
      {" "}
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4} lg={4}>
            <Link className={classes.link} to="/codesystems">
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  height: 240,
                  alignItems: "center",
                  ":hover": {
                    boxShadow: "0 0 11px rgba(33,33,33,.2)",
                  },
                }}
              >
                <Typography
                  component="h2"
                  variant="h6"
                  color="primary"
                  gutterBottom
                >
                  <strong>Code Systems</strong>
                </Typography>
                <Typography component="p" variant="h4" sx={{ paddingTop: 7 }}>
                  {codeSystems.length}
                </Typography>
              </Paper>
            </Link>
          </Grid>
          {/* Recent Deposits */}
          <Grid item xs={12} md={4} lg={4}>
            <Link className={classes.link} to="/valuesets">
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  height: 240,
                  alignItems: "center",
                  ":hover": {
                    boxShadow: "0 0 11px rgba(33,33,33,.2)",
                  },
                }}
              >
                <Typography
                  component="h2"
                  variant="h6"
                  color="primary"
                  gutterBottom
                >
                  <strong>Value Sets</strong>
                </Typography>
                <Typography component="p" variant="h4" sx={{ paddingTop: 7 }}>
                  {valueSets.length}
                </Typography>
              </Paper>
            </Link>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Link className={classes.link} to="/conceptmaps">
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  height: 240,
                  alignItems: "center",
                  ":hover": {
                    boxShadow: "0 0 11px rgba(33,33,33,.2)",
                  },
                }}
              >
                <Typography
                  component="h2"
                  variant="h6"
                  color="primary"
                  gutterBottom
                >
                  <strong>Concept Maps</strong>
                </Typography>
                <Typography component="p" variant="h4" sx={{ paddingTop: 7 }}>
                  {conceptMaps.length}
                </Typography>
              </Paper>
            </Link>
          </Grid>
        </Grid>
      </Container>
    </Box>

    // <>
    //   <div className="columns has-text-centered mt-4">
    //     <div className="column">
    //       <div className="is-size-6">CODE SYSTEMS</div>
    //       <div className="is-size-3 has-text-weight-semibold">
    //         {codeSystems.length}
    //       </div>
    //     </div>
    //     <div className="column">
    //       <div className="is-size-6">VALUE SETS</div>
    //       <div className="is-size-3 has-text-weight-semibold">
    //         {valueSets.length}
    //       </div>
    //     </div>
    //     <div className="column">
    //       <div className="is-size-6">CONCEPT MAPS</div>
    //       <div className="is-size-3 has-text-weight-semibold">
    //         {conceptMaps.length}
    //       </div>
    //     </div>
    //     <div className="column">
    //       <div className="is-size-6">DATA DICTIONARIES</div>
    //       <div className="is-size-3 has-text-weight-semibold">???</div>
    //     </div>
    //     <div className="column">
    //       <div className="is-size-6">QUESTIONNARIES</div>
    //       <div className="is-size-3 has-text-weight-semibold">???</div>
    //     </div>
    //   </div>
    // </>
  );
};

export default Home;
