import { TreeItem } from "@mui/lab";
import { IconButton, Stack, Typography } from "@mui/material";
import { ConceptModel } from "../models/Concept";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";
import ConceptChildrenTree from "./ConceptChildrenTree";
import { handleHighlightText } from "../helper/HighlightText";

interface Props {
  concept: ConceptModel;
  queryText: string;
}

const ConceptTreeItem: React.FC<Props> = ({ concept, queryText }) => {
  const [item, setItem] = useState<ConceptModel>(concept);

  const handleExpand = () => {
    let modifiedItem = { ...item, expanded: !item.expanded };
    setItem(modifiedItem);
  };

  return (
    <TreeItem
      nodeId={concept.code + concept.display}
      label={conceptTitle(concept, queryText)}
      sx={{ mb: 1 }}
    >
      <TreeItem
        nodeId={concept.display}
        label={conceptLabel("Display", handleHighlightText(queryText, concept.display))}
      />
      <TreeItem
        nodeId={concept.code}
        label={conceptLabel("Code", handleHighlightText(queryText, concept.code))}
      />
      <TreeItem
        nodeId={concept.csName}
        label={conceptLabel("Code System", concept.csName)}
      />
      <TreeItem
        nodeId={concept.csVersion}
        label={conceptLabel("Code System Version", concept.csVersion)}
      />
      <TreeItem
        nodeId={concept.language}
        label={conceptLabel("Language", concept.language)}
      />
      {concept.hasChildren > 0 && (
        <div>
          <IconButton
            aria-label="getConceptChildren"
            onClick={() => handleExpand()}
            sx={{ padding: "5px" }}
          >
            {item.expanded ? (
              <ExpandMoreIcon sx={{ width: 20 }} />
            ) : (
              <ChevronRightIcon sx={{ width: 20 }} />
            )}
          </IconButton>
          <b>Children</b>
        </div>
      )}
      {item.expanded && <ConceptChildrenTree id={item.id} />}
    </TreeItem>
  );
};

const conceptTitle = (concept: ConceptModel, queryText: string) => {
  return (
    <Stack direction="row">
      <Typography fontSize="16px" variant="body2">
        <b>{handleHighlightText(queryText, concept.code)}</b> - {handleHighlightText(queryText, concept.display)}
      </Typography>
    </Stack>
  );
};

const conceptLabel = (title: string, value: string) => {
  return (
    <Stack direction="row">
      <Typography fontSize="16px" variant="body2">
        <b>{title}</b> - {value}
      </Typography>
    </Stack>
  );
};

export default ConceptTreeItem;
