import { Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  Container,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  link: {
    display: "block",
    color: "white",
  },
  link2: {
    display: "block",
    color: "white",
    textDecoration: "none",
  }
});

const Header: React.FC = () => {
  const classes = useStyles();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" className="">
        <Container maxWidth="xl">
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              <Link className={classes.link2} to="/home">Terminology Service</Link>
            </Typography>

            <Link className={classes.link} to="/home">
              <Button color="inherit">Home</Button>
            </Link>
            
            <Link className={classes.link} to="/codesystems">
              <Button color="inherit">Code Systems</Button>
            </Link>
            <Link className={classes.link} to="/valuesets">
              <Button color="inherit">Value Sets</Button>
            </Link>
            <Link className={classes.link} to="/conceptmaps">
              <Button color="inherit">Concept Maps</Button>
            </Link>
            <Link className={classes.link} to="/search" aria-disabled>
              <Button color="inherit">Search</Button>
            </Link>
          </Toolbar>
        </Container>
      </AppBar>
    </Box>

    //TODO create burger menu function
    //for ideas: https://stackoverflow.com/questions/55015841/burger-menu-using-bulma-on-react-not-working
    // <>
    //   <nav className="navbar is-link">
    //     <div className="navbar-brand">
    //       <div className="navbar-start">
    //         <span className="navbar-burger burger">
    //           <span aria-hidden="true"></span>
    //           <span aria-hidden="true"></span>
    //           <span aria-hidden="true"></span>
    //         </span>
    //       </div>
    //     </div>
    //     <div className="navbar-menu is-active">
    //       <div className="navbar-start">
    //         <Link className="navbar-item" to="/home">
    //           Home
    //         </Link>
    //         <Link className="navbar-item" to="/search">
    //           Search
    //         </Link>
    //         <Link className="navbar-item" to="/codesystems">
    //           Code Systems
    //         </Link>
    //         <Link className="navbar-item" to="/valuesets">
    //           Value Sets
    //         </Link>
    //         <Link className="navbar-item" to="/conceptmaps">
    //           Concept Maps
    //         </Link>
    //         <Link className="navbar-item" to="/datadictionaries">
    //           Data Dictionaries
    //         </Link>
    //         <Link className="navbar-item" to="/translations">
    //           Translations
    //         </Link>
    //         <Link className="navbar-item" to="/forms">
    //           Forms
    //         </Link>
    //         <Link className="navbar-item" to="/analytics">
    //           Analytics
    //         </Link>
    //       </div>
    //     </div>
    //   </nav>
    // </>
  );
};

export default Header;
