import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import { ConceptModel } from "../models/Concept";
import { useEffect, useState } from "react";
import { getCodeSystems, searchConcepts } from "../services/data";
import { CodeSystemModel } from "../models/CodeSystemModel";
import SearchTreeView from "./SearchTreeView";
import SearchTable from "../components/SearchTable";

const Search: React.FC = () => {
  const [concepts, setConcepts] = useState<ConceptModel[]>([]);
  const [queryText, setQueryText] = useState<string>("");
  const [codeSystem, setCodeSystem] = useState<string>("All");
  const [showTreeView, setShowTreeView] = useState<boolean>(false);

  const [codeSystems, setCodeSystems] = useState<CodeSystemModel[]>([]);

  useEffect(() => {
    let isCancelled = false;

    getCodeSystems()
      .then((data) => {
        if (!isCancelled) {
          setCodeSystems(data);
        }
      })
      .catch((error) => console.log(error));

    return () => {
      isCancelled = true;
    };
  }, []);

  const handleSearch = () => {
    searchConcepts(queryText, codeSystem)
      .then((data) => {
        setConcepts(data.content);
      })
      .catch((error) => console.log(error));
  };

  const handleSearchTextChange = (event: any) => {
    setQueryText(event.target.value);
  };

  const handleShowTreeView = (event: any) => {
    setShowTreeView(event.target.checked);
  };

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        height: "calc(100vh - 64px)",
        overflow: "auto",
      }}
    >
      {" "}
      <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            // height: 240,
            alignItems: "left",
            backgroundColor: "#fff",
          }}
        >
          <Grid container={true}>
            <TextField
              id="outlined-read-only-input"
              label="Search text"
              sx={{ color: "black", width: "30%" }}
              size="small"
              onChange={handleSearchTextChange}
            ></TextField>
            <FormControl sx={{ ml: 2, minWidth: 200 }} size="small">
              <InputLabel id="demo-simple-select-label">Code System</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={codeSystem}
                label="Code System"
                defaultValue="All"
                onChange={(event) => setCodeSystem(event.target.value)}
              >
                <MenuItem value="All">All</MenuItem>
                {codeSystems.map((row) => (
                  <MenuItem key={row.id} value={row.id}>
                    {row.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button variant="contained" sx={{ ml: 2 }} onClick={handleSearch}>
              Search
            </Button>
            <FormControlLabel  control={<Checkbox sx={{ ml: 2 }} onChange={handleShowTreeView}/>} label="Show tree view" />
          </Grid>
          <Grid container={true} sx={{ mt: 2 }}>
            {
              showTreeView ? (
                <SearchTreeView concepts={concepts} queryText={queryText} />
              ) : (
                <SearchTable concepts={concepts} />
              )
            }
          </Grid>
        </Paper>
      </Container>
    </Box>
  );
};

export default Search;
