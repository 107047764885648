import config from "../config";

export async function getCodeSystems() {
  return await fetch(`${config.FHIR_TERM_SERVICE_SERVER_URL}/codesystem`).then(
    (response) => response.json()
  );
}
// getCodeSystemById

// Get all ValueSets
export async function getValueSets() {
  return await fetch(`${config.FHIR_TERM_SERVICE_SERVER_URL}/valueset`).then(
    (response) => response.json()
  );
}

// Get single/specific ValueSet from id
export async function getValueSetById(id: any) {
  return await fetch(`${config.FHIR_TERM_SERVICE_SERVER_URL}/valueset/${id}`).then(
    (response) => response.json()
  );
}

// Get all ValueSets
export async function getConceptMaps() {
  return await fetch(`${config.FHIR_TERM_SERVICE_SERVER_URL}/conceptmap`).then(
    (response) => response.json()
  );
}

// Search for Concepts by code or display
export async function searchConcepts(searchTerm: string, codeSystem: string) {
  if (codeSystem === "All") {
    codeSystem = "";
  }
  return await fetch(
    `${config.FHIR_TERM_SERVICE_SERVER_URL}/search/concept?query=${searchTerm}&sids=${codeSystem}`
  ).then((response) => response.json());
}

// Get concept children
export async function getConceptChildren(id: string) {
  return await fetch(
    `${config.FHIR_TERM_SERVICE_SERVER_URL}/codesystem/concept/children_of/${id}`
  ).then((response) => response.json());
}