import { ConceptMapModel } from "../models/ConceptMapModel";
import {
  Container,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";

export interface Props {
  conceptMaps: ConceptMapModel[];
}

const ConceptMapTable: React.FC<Props> = ({ conceptMaps }) => {
  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      <Paper
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          // height: 240,
          alignItems: "center",
          backgroundColor: "#fff",
        }}
      >
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow
                sx={{
                  "& th": {
                    fontWeight: 700,
                  },
                }}
              >
                <TableCell align="left">Id</TableCell>
                <TableCell align="left">Version</TableCell>
                <TableCell align="left">Status</TableCell>
                <TableCell align="left">Last updated</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {conceptMaps.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="left">{row.id}</TableCell>
                  <TableCell align="left">{row.version}</TableCell>
                  <TableCell align="left">{row.status}</TableCell>
                  <TableCell align="left">{row.lastUpdated}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Container>
  );
};

export default ConceptMapTable;
