import { useEffect, useState } from "react";
import CodeSystemTable from "../components/CodeSystemTable";
import { CodeSystemModel } from "../models/CodeSystemModel";
import { getCodeSystems } from "../services/data";
import { Box } from "@mui/material";

const CodeSystems: React.FC = () => {
  const [codeSystems, setCodeSystems] = useState<CodeSystemModel[]>([]);

  useEffect(() => {
    let isCancelled = false;

    getCodeSystems()
      .then((data) => {
        if (!isCancelled) {
          //console.log(data);
          setCodeSystems(data);
        }
      })
      .catch((error) => console.log(error));

    return () => {
      isCancelled = true;
    };
  }, []);

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        height: "calc(100vh - 64px)",
        overflow: "auto",
      }}
    >
      {" "}
      <CodeSystemTable codeSystems={codeSystems} />
    </Box>
  );
};

export default CodeSystems;
