import { Link } from "react-router-dom";
import { CodeSystemModel } from "../models/CodeSystemModel";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Container,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  link: {
    display: "block",
    textDecoration: "none",
  },
});

export interface Props {
  codeSystems: CodeSystemModel[];
}

const CodeSystemTable: React.FC<Props> = ({ codeSystems }) => {
  const classes = useStyles();

  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      <Paper
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          // height: 240,
          alignItems: "center",
          backgroundColor: "#fff",
        }}
      >
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow
                sx={{
                  "& th": {
                    fontWeight: 700,
                  },
                }}
              >
                <TableCell align="left">Name</TableCell>
                <TableCell align="left">Version</TableCell>
                <TableCell align="left">URL</TableCell>
                <TableCell align="left">Description</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {codeSystems.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="left">
                    {row.name === "defaultCodeSystem" ? (
                      row.name
                    ) : (
                      <Link className={classes.link} to={row.id}>
                        {row.name}
                      </Link>
                    )}
                  </TableCell>
                  <TableCell align="left">{row.version}</TableCell>
                  <TableCell align="left">{row.url}</TableCell>
                  <TableCell align="left">{row.description}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Container>
  );
};

export default CodeSystemTable;
