import { ConceptModel } from "../models/Concept";
import { TreeView } from "@mui/lab";
import { Grid } from "@mui/material";
import ConceptTreeItem from "./ConceptTreeItem";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

interface Props {
  concepts: ConceptModel[];
  queryText: string;
}

const ConceptTree: React.FC<Props> = ({ concepts, queryText }) => {
  return (
    <Grid sx={{ mt: 2 }}>
      <TreeView
        aria-label="file system navigator"
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
      >
        {concepts &&
          concepts.map((concept, index) => (
            <ConceptTreeItem concept={concept} key={index} queryText={queryText} />
          ))}
      </TreeView>
    </Grid>
  );
};

export default ConceptTree;
