import { Alert, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { ConceptModel } from "../models/Concept";

interface Props {
    concepts: ConceptModel[];
}

const SearchTable: React.FC<Props> = ({ concepts }) => {
    
    return (
        <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow
                    sx={{
                      "& th": {
                        fontWeight: 700,
                      },
                    }}
                  >
                    <TableCell align="left">Code</TableCell>
                    <TableCell align="left">Display</TableCell>
                    <TableCell align="left">Code System</TableCell>
                    <TableCell align="left">Code System Version</TableCell>
                    <TableCell align="left">Language</TableCell>
                  </TableRow>
                </TableHead>
                {concepts.length === 0 ? (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={5}>
                        <Stack sx={{ width: "100%" }} spacing={2}>
                          <Alert severity="info">No Data!</Alert>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    {concepts &&
                      concepts.map((row) => (
                        <TableRow
                          key={row.code}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="left">{row.code}</TableCell>
                          <TableCell align="left">{row.display}</TableCell>
                          <TableCell align="left">{row.csName}</TableCell>
                          <TableCell align="left">{row.csVersion}</TableCell>
                          <TableCell align="left">{row.language}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
    )
}

export default SearchTable;