export function handleHighlightText (queryText: string, text: string) {
    if (queryText.length === 0) return text;
    return text.split(queryText).reduce(
      (prev: any, current: any, i: any) => 
      i === 0
          ? [current]
          :
            prev.concat(
              <span key={queryText} style={{ backgroundColor: "yellow" }}>
                {queryText}
              </span>,
              current
            ),[],
    );
  };

//   export function Highlighted({text = '', highlight = ''}) {
//     if (!highlight.trim()) {
//       return <span>{text}</span>
//     }
//     const regex = new RegExp(`(${_.escapeRegExp(highlight)})`, 'gi')
//     const parts = text.split(regex)
//     return (
//       <span>
//          {parts.filter(part => part).map((part, i) => (
//              regex.test(part) ? <mark key={i}>{part}</mark> : <span key={i}>{part}</span>
//          ))}
//      </span>
//     )
//  }
