import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import "./App.css";
import Header from "./components/Header";
import CodeSystem from "./pages/CodeSystem";
import CodeSystems from "./pages/CodeSystems";
import Home from "./pages/Home";
import Search from "./pages/Search";
import ValueSets from "./pages/ValueSets";
import ValueSet from "./pages/ValueSet";
import ConceptMaps from "./pages/ConceptMaps";

function AppRouter() {
  return (
    <div className="App">
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/home" element={<Home />}></Route>
          <Route path="/search" element={<Search />}></Route>
          <Route path="/codesystems" element={<CodeSystems />}></Route>
          <Route path="/codesystems/:id" element={<CodeSystem />}></Route>
          <Route path="/valuesets" element={<ValueSets />}></Route>
          <Route path="/valuesets/:id" element={<ValueSet />}></Route>
          <Route path="/conceptmaps" element={<ConceptMaps />}></Route>
          {/* <Route path="/conceptmaps/:id" element={<ValueSet />}></Route> */}
        </Routes>
      </Router>
    </div>
  );
}

export default AppRouter;
