import { Alert, Container, Stack } from "@mui/material";
import { ConceptModel } from "../models/Concept";
import ConceptTree from "../components/ConceptTree";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    "&.MuiContainer-root": {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
});

interface Props {
  concepts: ConceptModel[];
  queryText: string;
}

const SearchTreeView: React.FC<Props> = ({ concepts, queryText }) => {
  const classes = useStyles();

  return concepts.length === 0 ? (
    <Stack sx={{ width: "100%" }} spacing={2}>
      <Alert severity="info">No Data!</Alert>
    </Stack>
  ) : (
    <Container
      className={classes.root}
      sx={{ maxWidth: "100%", paddingLeft: 0 }}
      maxWidth={false}
    >
      <ConceptTree concepts={concepts} queryText={queryText} />
    </Container>
  );
};

export default SearchTreeView;
