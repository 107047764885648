import { useEffect, useState } from "react";
import ConceptMapTable from "../components/ConceptMapTable";
import { ConceptMapModel } from "../models/ConceptMapModel";
import { getConceptMaps } from "../services/data";
import { Box } from "@mui/material";

const ConceptMaps: React.FC = () => {
  const [conceptMaps, setConceptMaps] = useState<ConceptMapModel[]>([]);

  useEffect(() => {
    let isCancelled = false;

    getConceptMaps()
      .then((data) => {
        if (!isCancelled) {
          setConceptMaps(data);
        }
      })
      .catch((error) => console.log(error));

    return () => {
      isCancelled = true;
    };
  }, []);

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        height: "calc(100vh - 64px)",
        overflow: "auto",
      }}
    >
      {" "}
      <ConceptMapTable conceptMaps={conceptMaps} />
    </Box>
  );
};

export default ConceptMaps;
