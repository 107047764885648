import { useEffect, useState } from "react";
import ValueSetTable from "../components/ValueSetTable";
import { ValueSetModel } from "../models/ValueSetModel";
import { getValueSets } from "../services/data";
import { Box } from "@mui/material";

const ValueSets: React.FC = () => {
  const [valueSets, setValueSets] = useState<ValueSetModel[]>([]);

  useEffect(() => {
    let isCancelled = false;

    getValueSets()
      .then((data) => {
        if (!isCancelled) {
          setValueSets(data);
        }
      })
      .catch((error) => console.log(error));

    return () => {
      isCancelled = true;
    };
  }, []);

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        height: "calc(100vh - 64px)",
        overflow: "auto",
      }}
    >
      {" "}
      <ValueSetTable valueSets={valueSets} />
    </Box>
  );
};

export default ValueSets;
