import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getValueSetById } from "../services/data";
import { ValueSetDataModel } from "../models/ValueSetDataModel";
import ValueSetDetails from "../components/ValueSetDetails";

const ValueSet: React.FC = (props) => {
  const { id } = useParams();
  const [valueSetData, setValueSetData] = useState<
    ValueSetDataModel | undefined
  >();

  useEffect(() => {
    //get more data on the Value Set
    let isCancelled = false;

    getValueSetById(id)
      .then((data) => {
        if (!isCancelled) {
          setValueSetData(data);
        }
      })
      .catch((error) => console.log(error));
    return () => {
      isCancelled = true;
    };
  }, [id]);

  return <ValueSetDetails valueSetData={valueSetData} />;
};

export default ValueSet;
