import { ValueSetDataModel } from "../models/ValueSetDataModel";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";

export interface Props {
  valueSetData: ValueSetDataModel | undefined;
}

const ValueSetDetails: React.FC<Props> = ({ valueSetData }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        height: "calc(100vh - 64px)",
        overflow: "auto",
      }}
    >
      {" "}
      {valueSetData && (
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              // height: 240,
              alignItems: "center",
              backgroundColor: "#fff",
            }}
          >
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={6}>
                <TextField
                  id="outlined-read-only-input"
                  label="Name"
                  variant="standard"
                  defaultValue={valueSetData?.name}
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                  }}
                  InputLabelProps={{
                    style: { fontSize: 20, fontWeight: "bold" },
                  }}
                  sx={{ color: "black", width: "95%" }}
                  size="small"
                ></TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="outlined-read-only-input"
                  label="Status"
                  variant="standard"
                  defaultValue={valueSetData?.status}
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                  }}
                  InputLabelProps={{
                    style: { fontSize: 20, fontWeight: "bold" },
                  }}
                  sx={{ color: "black", width: "95%" }}
                  size="small"
                ></TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="outlined-read-only-input"
                  label="Description"
                  variant="standard"
                  multiline
                  defaultValue={valueSetData?.description}
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                  }}
                  InputLabelProps={{
                    style: { fontSize: 20, fontWeight: "bold" },
                  }}
                  sx={{ color: "black", width: "95%" }}
                  size="small"
                ></TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="outlined-read-only-input"
                  label="URL"
                  variant="standard"
                  defaultValue={valueSetData?.url}
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                  }}
                  InputLabelProps={{
                    style: { fontSize: 20, fontWeight: "bold" },
                  }}
                  sx={{ color: "black", width: "95%" }}
                  size="small"
                ></TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="outlined-read-only-input"
                  label="Purpose"
                  variant="standard"
                  defaultValue={valueSetData?.purpose}
                  multiline
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                  }}
                  InputLabelProps={{
                    style: { fontSize: 20, fontWeight: "bold" },
                  }}
                  sx={{ color: "black", width: "95%" }}
                  size="small"
                ></TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="outlined-read-only-input"
                  label="Version"
                  variant="standard"
                  defaultValue={valueSetData?.version}
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                  }}
                  InputLabelProps={{
                    style: { fontSize: 20, fontWeight: "bold" },
                  }}
                  sx={{ color: "black", width: "95%" }}
                  size="small"
                ></TextField>
              </Grid>
              <Grid item xs={6}>
                <InputLabel sx={{ fontSize: "16px", fontWeight: "bold" }}>
                  Concepts
                </InputLabel>
                <TextField
                  id="outlined-read-only-input"
                  variant="standard"
                  multiline
                  label="System"
                  defaultValue={valueSetData?.compose.include[0].system}
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                  }}
                  sx={{ color: "black", width: "95%" }}
                  size="small"
                ></TextField>
                <TextField
                  id="outlined-read-only-input"
                  variant="standard"
                  multiline
                  label="Version"
                  defaultValue={valueSetData?.compose.include[0].version}
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                  }}
                  sx={{ color: "black", width: "95%" }}
                  size="small"
                ></TextField>
                <InputLabel sx={{ fontSize: 12 }}>Concepts</InputLabel>
                {/* <div><pre>{JSON.stringify(valueSetData.compose.include[0].concept, null, 2)}</pre></div> */}
                {valueSetData.compose.include.map((include) => (
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow
                          sx={{
                            "& th": {
                              fontWeight: 700,
                            },
                          }}
                        >
                          <TableCell align="left">Code</TableCell>
                          <TableCell align="left">Display</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {include.concept.map((row) => (
                          <TableRow
                            key={row.code}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell align="left">{row.code}</TableCell>
                            <TableCell align="left">{row.display}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ))}
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClickOpen}
                >
                  Show Value Set As JSON
                </Button>
                <ValueSetJsonDialog
                  selectedValueSet={valueSetData}
                  open={open}
                  onClose={handleClose}
                />
              </Grid>
            </Grid>
          </Paper>
        </Container>
      )}
    </Box>
    // <>

    // <div className="field">
    //   <label className="label">Name</label>
    //   {valueSetData?.name}
    // </div>
    // <div className="field">
    //   <label className="label">ID</label>
    //   {valueSetData?.id}
    // </div>
    // <div className="field">
    //   <label className="label">Description</label>
    //   {valueSetData?.description}
    // </div>
    // <div className="field">
    //   <label className="label">Status</label>
    //   {valueSetData?.status}
    // </div>
    // <div className="field">
    //   <label className="label">URL</label>
    //   {valueSetData?.url}
    // </div>
  );
};

export interface SimpleDialogProps {
  open: boolean;
  selectedValueSet: ValueSetDataModel;
  onClose: () => void;
}

const ValueSetJsonDialog = (props: SimpleDialogProps) => {
  const { onClose, selectedValueSet, open } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open} maxWidth="xl">
      <DialogTitle>
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}{" "}
        {selectedValueSet.name}
      </DialogTitle>
      <DialogContent>
        <pre>{JSON.stringify(selectedValueSet, null, 2)}</pre>
        <pre>{JSON.stringify(selectedValueSet, null, 2)}</pre>
      </DialogContent>
    </Dialog>
  );
};

export default ValueSetDetails;
