import { ConceptModel } from "../models/Concept";
import { TreeView } from "@mui/lab";
import { Grid } from "@mui/material";
import ConceptTreeItem from "./ConceptTreeItem";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useEffect, useState } from "react";
import { getConceptChildren } from "../services/data";

interface Props {
  id: string;
}

const ConceptChildrenTree: React.FC<Props> = ({ id }) => {
  const [concepts, setConcepts] = useState<ConceptModel[]>([]);

  useEffect(() => {
    let isCancelled = false;

    getConceptChildren(id)
      .then((data) => {
        let contents = data as ConceptModel[];
        contents.forEach((content) => {
          content.expanded = false;
        });
        if (!isCancelled) {
          setConcepts(contents);
        }
      })
      .catch((error) => console.log(error));
  });

  return (
    <Grid sx={{ ml: 2 }}>
      <TreeView
        aria-label="file system navigator"
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
      >
        {concepts &&
          concepts.map((concept, index) => (
            <ConceptTreeItem concept={concept} key={index} queryText="" />
          ))}
      </TreeView>
    </Grid>
  );
};

export default ConceptChildrenTree;
